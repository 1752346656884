import React from 'react';
import {Route, Switch} from 'react-router-dom';
import { Spinner } from 'office-ui-fabric-react';
import { Impressum } from './pages/Impressum';

const Home = React.lazy(() => import('./pages/Home'));
const Sproodle = React.lazy(() => import('./pages/Sproodle'));

export const Routes = () => (
    <Switch>
        <React.Suspense fallback={<Spinner/>}>
            <Route path="/" component={Home} exact />
            <Route path="/Sproodle/:id" render={(a) => <Sproodle id={a.match.params.id} />} />
            <Route path="/Impressum" component={Impressum} />
        </React.Suspense>
    </Switch>
)