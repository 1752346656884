import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from './components/Header/Header';

interface IProps {
    children: JSX.Element;
}

export const Layout = (props: IProps) => {
  return (
      <>
          <div className="ms-Grid">
              <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm0 ms-xl2"/>
                  <div className="ms-Grid-col ms-sm12 ms-xl8">
                      {props.children}
                  </div>
                  <div className="ms-Grid-col ms-sm0 ms-xl2"/>
              </div>
          </div>
      </>
  );
}

