import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { Fabric, initializeIcons, Customizer } from 'office-ui-fabric-react';
import { FluentCustomizations } from '@uifabric/fluent-theme';
import { Routes } from './Routes';
import './App.css';
import { Layout } from './Layout';

const App: React.FC = () => {
  initializeIcons();

  return (
    <Customizer {...FluentCustomizations}>
      <Fabric>
        <Layout>
          <Router>
            <Routes />
          </Router>
        </Layout>
      </Fabric>
    </Customizer>
  );
};

export default App;
