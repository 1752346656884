import { Configuration } from "msal";

export const getMsalConfig = () => (
    {
        auth: {
            authority: window.config.MSAL_AUTHORITY,
            clientId: window.config.MSAL_CLIENTID,
            redirectUri: `${window.location.protocol}//${window.location.host}`,
        }
    } as Configuration
);