import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { ConfigService } from './services/ConfigService';
import { getMsalConfig } from './config/msalConfig';
import { UserAgentApplication } from 'msal';

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
window.appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: "e30826da-be1e-4c63-8667-79d3dc68ce09",
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
window.appInsights.loadAppInsights();

ConfigService.GetConfig().then((config) => {
    window.config = config;

    window.app = new UserAgentApplication(getMsalConfig());

    window.app.handleRedirectCallback((err, response) => {
        if(err) {
            console.error(err);
            window.app.loginRedirect();
        } 
    });
    
    ReactDOM.render(<App />, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
});
