import { IconButton } from "office-ui-fabric-react";
import * as React from "react";

export const Impressum = () => (
  <div>
    <IconButton
      iconProps={{ iconName: "back", style: { fontSize: "40px" } }}
      style={{ height: "50px", width: "50px" }}
      href="#"
    />
    <h1>Impressum</h1>
    <h2>Otto (GmbH & Co KG)</h2>
    <h3>Workplace Services</h3>
    <p>
      Werner-Otto-Straße 1-7
      22179 Hamburg
    </p>
    <p>
      AG Hamburg HR A 62 024
    </p>

    <p>
      <b>Umsatzsteueridentifikationsnr.</b> DE118475690
    </p>

    <p>
      <b>Persönlich haftend</b>
      <br/>
      Verwaltungsgesellschaft Otto mbH
      AG Hamburg HR B 13 762
    </p>
    
    <p>
      <b>Vertreten durch</b>
      <br/>
      Alexander Birken (Vorstandsvorsitzender)
      Dr. Marcus Ackermann
      Sergio Bucher
      Sebastian Klauke
      Petra Scharner-Wolff
      Kay Schiebur
    </p>

    <p>
      <b>Aufsichtsrat</b>
      <br/>
      Prof. Dr. Michael Otto (Aufsichtsratsvorsitzender)
    </p>
  </div>
);